import React from "react";
import icon404 from '../images/lupa.svg';
import s from '../style/404Page.module.scss';
import Button from '../components/Button.js';
import {SEO} from "../components/seo";

const NotFoundPage = () => {
  return(
    <div className={s.container}>
      <SEO title="404: Not found"/>
      <img className={s.imgDesk} src={icon404} alt={"Imagen"}/>
      <p className={s.oops}>Oops!</p>
      <p className={s.line}>Página no encontrada</p>
      <h1 className={s.number}>404</h1>
      <Button text={"Volver a la página principal"} href="/"/>
   </div>
  )
}
export default NotFoundPage
